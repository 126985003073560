import React, { useEffect, useState } from "react";
import { getTableDataSingleDay, updateAttendeesCount } from "../Api/Api"; // 새로운 API 함수 포함
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Table.module.css";

const TotalDetailCheckTable = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1); 
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(today);
    const [editIndex, setEditIndex] = useState(null); // 수정 중인 행의 인덱스
    const [editedValue, setEditedValue] = useState(""); // 수정된 인원 수

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getTableDataSingleDay(
                    selectedDate.toISOString().split("T")[0]
                );
                if (result && result.data && result.data.length > 0) {
                    console.log("Fetched Data:", result.data); // 데이터 출력
                    setData(result.data);
                } else {
                    console.error("Invalid response format or no data available");
                    setData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setData([]);
            }
        };
        fetchData();
    }, [selectedDate]);

    const handleEditClick = (index, currentAttendees) => {
        setEditIndex(index);
        setEditedValue(currentAttendees); // 현재 인원 수로 값 초기화
    };

    const handleSaveClick = async (id) => {
        console.log("Saving for ID:", id);
        try {
            await updateAttendeesCount(id, editedValue);
            
            // 업데이트 성공 후 상태 업데이트
            setData(prevData => 
                prevData.map(row => 
                    row.id === id ? { ...row, total_attendees: editedValue } : row
                )
            );
    
            // 상태 초기화
            setEditIndex(null);
            setEditedValue("");
        } catch (error) {
            console.error("Error updating attendees:", error);
        }
    };

    return (
        <div className={styles.tableContainer}>
            <div className={styles.datePickerContainer_02}>
                <div className={styles.datePickerWrapper}>
                    <label>조회 날짜: </label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className={styles.custom_datepicker__input}
                    />
                </div>
            </div>

            <div className={styles.tablesbox}>
                <table className={styles.tables}>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>인원 수 (명)</th>
                            <th>날짜 및 시간</th>
                            <th>수정</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((row, index) => (
                                <tr key={index}>
                                    <td className={styles.tdIndex}>{index + 1}</td>
                                    <td className={styles.tdModify}>
                                        {editIndex === index ? (
                                            <input
                                                type="number"
                                                value={editedValue}
                                                onChange={(e) => setEditedValue(e.target.value)}
                                                className={styles.inputModify}
                                            />
                                        ) : (
                                            row.total_attendees.toLocaleString()
                                        )}
                                    </td>
                                    <td className={styles.tdDate}>
                                        {row.attendance_time.split(" ")[1].startsWith('00:') 
                                            ? `${new Date(selectedDate.setDate(selectedDate.getDate() - 1)).toISOString().split('T')[0]} 23시 ~ ${new Date(selectedDate.setDate(selectedDate.getDate() + 1)).toISOString().split('T')[0]} 00시`
                                            : `${new Date(selectedDate).toISOString().split('T')[0]} ${row.attendance_time.split(" ")[1].split(":")[0]}시 ~ ${String(parseInt(row.attendance_time.split(" ")[1].split(":")[0]) + 1).padStart(2, '0')}시`}
                                    </td>
                                    <td className={styles.tdBtn}>
                                        {editIndex === index ? (
                                            <button className={styles.saveBtn} onClick={() => handleSaveClick(row.id)}>저장</button>
                                        ) : (
                                            <button className={styles.modifyBtn} onClick={() => handleEditClick(index, row.total_attendees)}>수정</button>
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">데이터가 없습니다.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TotalDetailCheckTable;
