import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader"; // 스피너 추가
import styles from "./Chart.module.css";
import { getTotalPersonsDailyCamera } from "../Api/Api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import "chartjs-adapter-date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";  // Datalabels 플러그인 불러오기

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels  // Datalabels 플러그인 등록
);

const ChartACamera = () => {
  // 오늘 날짜를 기본값으로 설정
  const today = new Date(); // 현재 날짜
  today.setDate(today.getDate() - 1); // 하루 전날로 설정
  const [data, setData] = useState([]); // 차트에 표시할 데이터를 관리
  const [loading, setLoading] = useState(true); // 데이터를 가져오는 동안 로딩 상태를 관리
  const [startDate, setStartDate] = useState(new Date("2024-09-28")); // 시작 날짜 상태
  const [endDate, setEndDate] = useState(today); // 종료 날짜 상태

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // startDate와 endDate를 YYYY-MM-DD 형식으로 변환
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = endDate.toISOString().split("T")[0];
        
        const result = await getTotalPersonsDailyCamera(formattedStartDate, formattedEndDate);  // API 호출
        console.log("ChartA API result:", result);

        if (result && result.data && result.data.length > 0) {
          setData(result.data);  // 데이터가 있을 때 설정
        } else {
          console.log("No data received");
          setData([]);  // 데이터가 없을 때 빈 배열로 설정
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);  // 에러 발생 시 빈 배열로 설정
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  const chartData = {
    labels: data.length > 0 ? data.map(item => item.date) : [], // data가 있을 때만 map 실행
    datasets: [
      {
        label: "날짜별 인원 수",
        data: data.length > 0 ? data.map(item => item.total_attendees) : [],  // data가 있을 때만 map 실행
        borderColor: "rgba(210, 160, 229, 1)", // 선 색상
        backgroundColor: "rgba(210, 160, 229, 0.2)", // 채우기 색상
        borderWidth: 2, // 선 굵기
        pointRadius: 4,
        pointHoverRadius: 8,
        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
        pointBorderColor: "rgba(210, 160, 229, 1)", // 데이터 포인트의 테두리 색상
        pointHoverBackgroundColor: "#fff", // 포인트 호버 시 배경색
        pointHoverBorderColor: "rgba(210, 160, 229, 1)" // 포인트 호버 시 테두리 색상
      }
    ]
  };
  
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: 14,
            family: "Arial",
            color: "#000"
          }
        }
      },
      tooltip: {
        enabled: true,
        backgroundColor: "rgba(0,0,0,0.7)",
        titleColor: "#fff",
        bodyColor: "#fff",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        callbacks: {
          // 첫째 줄: 인원 수 표시 (명 추가)
          title: function(context) {
            if (context && context[0] && context[0].raw !== undefined) {
              const attendees = context[0].raw; // 인원 수 가져오기
              return attendees.toLocaleString() + '명'; // 인원 수 뒤에 '명' 추가
            }
            return ''; // 데이터가 없을 경우 빈 문자열 반환
          },
          // label 콜백: 아이콘과 날짜를 함께 표시
          label: function(context) {
            if (context && context.label) {
              const date = context.label; // 날짜 정보 가져오기
              return `${date}`; // 날짜를 표시 (아이콘은 기본으로 붙음)
            }
            return ''; // 데이터가 없을 경우 빈 문자열 반환
          },
          // afterLabel 콜백: 사용하지 않음
          afterLabel: function() {
            return ''; // 빈 문자열 반환
          }
        }
      },
      datalabels: {
        display: true,
        align: 'top', 
        color: "rgba(210, 160, 229, 1)",
        font: {
          size: 16,
          weight: 'bold'
        },
        formatter: (value) => {
          return value.toLocaleString(); 
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.3)"
        },
        ticks: {
          color: "#000",
          font: {
            size: 12
          }
        },
        offset: true,
      },
      y: {
        grid: {
          display: true,
          color: "rgba(200, 200, 200, 0.3)"
        },
        ticks: {
          color: "#000",
          font: {
            size: 12
          }
        },
        afterDataLimits: (axis) => {
          const max = axis.max;
          axis.max = max * 1.1;
        }
      }
    }
  };
  
  

  return (
    <div className={styles.chartContainer}>
      <div className={`${styles.datePickerContainer} ${styles.chart}`}>
        <div className={styles.datePickerWrapper}>
          <label>시작 날짜: </label>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            className={styles.custom_datepicker__input}
          />
        </div>
        <div className={styles.datePickerWrapper}>
          <label>종료 날짜: </label>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
            className={styles.custom_datepicker__input}
          />
        </div>
      </div>
      {data.length > 0 ? (
        <Line data={chartData} options={chartOptions} className={styles.chart} />
      ) : (
        <div>데이터가 없습니다.</div>
      )}
    </div>
  );
};

export default ChartACamera;
