import React, {useState} from 'react';
import styles from "./PasswordPrompt.module.css";

const PasswordPrompt = ({onClose, onSubmit}) => {
    const [password, setPassword] = useState("");

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = () => {
        // 임시 비밀번호 검증 (여기에서는 '1234'로 설정)
        if (password === '1234') {
            alert("비밀번호가 일치합니다!");
            onSubmit();
        } else {
            alert("비밀번호가 일치하지 않습니다.");
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(); // Enter 키가 눌리면 handleSubmit 함수 실행
        }
    };

    // 모달 외부를 클릭하면 닫기
    const handleBackgroundClick = () => {
        onClose();
    };

    // 모달 내부 클릭 시 이벤트 전파 방지
    const handleModalContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={styles.modal} onClick={handleBackgroundClick}>
            <div className={styles.modalContent} onClick={handleModalContentClick}>
                <h2>비밀번호를 입력하세요</h2>
                <input 
                    type="password" 
                    className={styles.passwordInput}
                    value={password} 
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown} // Enter 키 처리
                    placeholder="비밀번호 입력"
                />
                <div className={styles.buttonGroup}>
                    <button className={styles.passwordPromptBtn} onClick={handleSubmit}>확인</button>
                </div>
            </div>
        </div>
    );
};

export default PasswordPrompt;
